import { Component, Input, OnInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener } from "@angular/core";
import { CommentsService } from "./comments.service";
import { CommentInterface } from "src/app/types/comment.interface";
import { SocketService } from "src/app/services/socket.service";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-component-container',
    templateUrl: './comments-container.component.html',
    styleUrls: ['./comments-container.component.css']
})

export class CommentContainer implements OnInit{
    @ViewChild('commentContainer') commentContainer: ElementRef;
    @Input() height:any;
    @Input() currentUserId: string;
    @Input() mandatId: string;

    public rootId = null;
    public msgListHeight = 0;
    public comments: CommentInterface[] = [];
    public commentSubscriber : Subscription;
    public innerHeight = 0;

    constructor(private commentsService: CommentsService, public cd : ChangeDetectorRef, public socketService : SocketService){

    }
    
    ngOnInit(): void {
        this.manageHeight();
        this.commentList();
        this.socketService.connect();
        this.startSubscribing();
    }

    manageHeight(){
        this.height -= 240; 
        this.msgListHeight = this.height - 140;
    }

    startSubscribing(){
        // this.commentSubscriber = this.socketService.commentSubject.subscribe(({ type, comment }) => {
        //     this.subscribedComment({ type, comment });
        // })
    }

    subscribedComment({ type, comment }){
        if((comment.userId !== this.currentUserId) && (comment.mandatId === this.mandatId) && (type === 'new_comment')){
            this.comments = [comment, ...this.comments];
            this.scroll();
        }

        if((comment.userId !== this.currentUserId) && (comment.mandatId === this.mandatId) && (type === 'edit_comment')){
            this.manageEdit(comment);
        }

        if((comment.userId !== this.currentUserId) && (comment.mandatId === this.mandatId) && (type === 'delete_comment')){
            this.comments.splice(this.comments.findIndex(ele => ele.id === comment.id), 1);
        }
    }

    commentList(){
        this.commentsService.getComments(this.mandatId).subscribe((comments) =>{
            this.comments = comments;
        });
    }

    createComment({text, parentId}){
        if(text?.trim()?.length){
            this.commentsService.createComment(text.trim(),parentId, this.mandatId).subscribe((createdComment:any) => {
                this.comments = [createdComment.comment, ...this.comments];
                this.scroll();
            })
        }
    }

    updateComment({text, commentId}) {
        if(text.length){
            this.commentsService.updateComment(commentId, text.trim()).subscribe((updatedComment:any) => {                
                this.manageEdit(updatedComment.comment);
            })
            
        }
    }

    deleteComment(id){
        this.comments.splice(this.comments.findIndex(ele => ele.id === id), 1);
        this.commentsService.deleteComment(id).subscribe(() => {});
    }

    scroll(){
        let scrollToBottom = false;
        const { scrollHeight, scrollTop, offsetHeight } = this.commentContainer.nativeElement;
        if(scrollHeight - offsetHeight - scrollTop < 1){
            scrollToBottom = true;
        }
        this.cd.detectChanges();
        if(scrollToBottom)
            this.commentContainer.nativeElement.scrollTop = this.commentContainer.nativeElement.scrollHeight + 50;
    }

    manageEdit(updatedComment){
        this.comments.forEach((comment : any) => {
            if(parseInt(comment.id) === updatedComment.id){
                comment.body = updatedComment.body;
                comment.updatedAt = updatedComment.updatedAt;
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if(event.target.innerHeight !== this.innerHeight)
            this.manageHeight();

        this.innerHeight = event.target.innerHeight;
    }

    ngOnDestroy(){
        this.socketService.disconnect();
        this.commentSubscriber.unsubscribe();
    }
}