import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'app-appalerts',
  templateUrl: './appalerts.component.html',
  styleUrls: ['./appalerts.component.css'],
  /* animations: [
    trigger('state', [
      transition(':enter', [
        style({ bottom: '-100px', transform: 'translate(-50%, 0%) scale(0.3)' }),
        animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({
          transform: 'translate(-50%, 0%) scale(1)',
          opacity: 1,
          bottom: '20px'
        })),
      ]),
      transition(':leave', [
        animate('150ms cubic-bezier(0.4, 0.0, 1, 1)', style({
          transform: 'translate(-50%, 0%) scale(0.3)',
          opacity: 0,
          bottom: '-100px'
        }))
      ])
    ])
  ] */
})
export class AppalertsComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public matSnackBarRef: MatSnackBarRef<any>) { }

  ngOnInit(): void {
  }

}
