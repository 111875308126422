import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { SharedService } from './shared.service';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    public sharedService: SharedService
  ) {}
  intercept(req, next) {
    let headers = {};
    let params = req.params;
    let responseType = req.responseType;

    if (this.sharedService.loggedIn()) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    }
    if (!req.params.get('isFormDataRequest')) {
      params = params.delete('isFormDataRequest');
      headers['Content-Type'] = 'application/json';
    }
    if (req.params.get('isDownloadRequest')) {
      params = params.delete('isDownloadRequest');
      responseType = 'blob';
    }
    const tokenizedReq = req.clone({
      setHeaders: headers,
      params,
      responseType,
    });
    return next.handle(tokenizedReq).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.sharedService.logoutUser();
            this.sharedService.open('Sie wurden automatisch abgemeldet.', 'failure');
          }
          return throwError(err);
        }
        return new Observable<HttpEvent<any>>();
      })
    );
  }
}
