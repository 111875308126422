import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../userlogin/user.service';
import { configData } from '../../config.data';

@Injectable({
  providedIn: 'root'
})
export class WerkstattPartnerService {

  constructor(private http: HttpClient, private router: Router,
    private user: UserService) { }

  expressLink = configData.SERVER + 'wpartner/';
  // Create a new Partner
  createPartner(body: any) {
    return this.http.post(this.expressLink + 'wpartner/new' + '/?autor=' + this.user.loggedinUser, body, {
      observe: 'body',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get all partners
  listPartners() {
    return this.http.get(this.expressLink + 'wpartners/', {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get all partners View
  listPartnersView() {
    return this.http.get(this.expressLink + 'wpartnersView/', {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get one partner details
  viewOnePartner(partnerID) {
    return this.http.get(this.expressLink + 'wpartner/' + partnerID, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Update Partner by Id
  updatePartner(body: any, partnerID) {
    return this.http.put(this.expressLink + 'updatewpartner/' + partnerID + '/?autor=' + this.user.loggedinUser, body, {
      observe: 'body',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Delete the Partner
  deletePartner(partnerID) {
    return this.http.delete(this.expressLink + 'deletewpartner/' + partnerID + '/?autor=' + this.user.loggedinUser, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
}
