import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: 'app-comment-form',
    templateUrl: './commentForm.component.html',
    styleUrls: ['./commentForm.component.css']
})

export class CommentFormComponent implements OnInit {
    @ViewChild('textarea') textArea: ElementRef;

    @Input() initialText = '';
    @Input() parentId = null;
    @Input() commentId = null;
    @Input() isEdit = false;
    @Input() isReply = false;

    @Output() submit = new EventEmitter<{text:string, parentId:string}>();
    @Output() updateComment = new EventEmitter<{text:string, commentId:string}>();
    @Output() handleCancel = new EventEmitter<boolean>();

    public commentForm: FormGroup;

    constructor(public fb : FormBuilder) {}

    ngOnInit(): void {
        this.commentForm = this.fb.group({
            text: [this.initialText, Validators.compose([Validators.maxLength(1000)])]
        });
    }
    
    ngAfterViewInit(){
        this.textArea.nativeElement.focus();
    }

    handleSubmit(){
        if(this.commentForm.status.toLowerCase() === 'invalid'){ return };

        if(this.commentId)
            return this.edit();
        this.create();
    }

    create(){
        this.submit.emit({text : this.commentForm.value.text, parentId : this.parentId});
        this.commentForm.patchValue({ text : '' });
        if(this.parentId)
            this.cancel();
    }

    edit(){
        this.updateComment.emit({text : this.commentForm.value.text, commentId : this.commentId});
        this.cancel();
    }

    keyDownHandle(e){
        if (e.keyCode == 13 && !e.shiftKey && !this.isEdit && !this.isReply) {
            this.handleSubmit();
            return false;
        }
    }

    cancel(){
        this.handleCancel.emit(false);
    }
}