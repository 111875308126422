import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Mandat } from '../../../models/mandat';
import { MandateService } from 'src/app/components/mandate/mandate.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../..//components/shared/confirmation-dialog/confirmation-dialog.component';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/services/shared.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MandatsTableComponent } from '../../shared/mandats-table/mandats-table.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-listmandate',
  templateUrl: './listmandate.component.html',
  styleUrls: ['./listmandate.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ListmandateComponent implements OnInit, OnDestroy {

  //#region Initializations

  mandate: Mandat[] = [];
  private subs = new SubSink();

  @ViewChild(MandatsTableComponent) child: MandatsTableComponent;

  //#endregion
  constructor(private router: Router, private formBuilder: FormBuilder,
    private mandateService: MandateService, private dialog: MatDialog,
    private sharedService: SharedService,
    private snackbarService: SnackbarService,
    public datepipe: DatePipe) {
    this.listMandate();
  }
  
  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  // List mandate
  listMandate() {
    this.sharedService.progress.next(true);
    this.subs.sink = this.mandateService.viewMandateList()
      .subscribe(
        (mandate: Mandat[]) => {
          this.child.loadTable(mandate);
          this.sharedService.progress.next(false);
        },
        err => {
          this.sharedService.progress.next(false);
        }
      );
  }

}
