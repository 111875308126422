import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {



  private snackbarSubject = new Subject<any>();
  public snackbarState = this.snackbarSubject.asObservable();
  public snackbarRef;
  constructor() { }

  show(message, type?) {
    console.log(message);
    this.snackbarSubject.next({
      show: true,
      message,
      type
    });
  }




}
