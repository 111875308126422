import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  Router
} from '@angular/router';
import { WerkstattPartner } from '../../../models/werkstatt';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SharedService } from 'src/app/services/shared.service';
import { WerkstattPartnerService } from 'src/app/components/werkstattpartners/werkstattpartner.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Helpers } from '../../shared/helper-functions';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-listpartners',
  templateUrl: './list-werkstatt-partners.component.html',
  styleUrls: ['./list-werkstatt-partners.component.css', '../../../../tableStyles.scss']
})
export class ListWerkstattPartnersComponent implements OnInit, OnDestroy {

  //#region Initializations
  partners: WerkstattPartner[] = [];
  displayedColumns = ['name', 'plz', 'ort', 'email', 'telefonNummer'];
  dataSource = new MatTableDataSource(this.partners);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  totalRecords = 0;
  filteredRecords = 0;
  helpers = new Helpers();
  private subs = new SubSink();
  anreden = [
    { id: 0, value: '-- Anrede auswählen --' },
  ];
  titels = [
    { id: 0, value: '-- Titel auswählen --' }
  ];
  //#endregion

  constructor(public router: Router, private partnerService: WerkstattPartnerService,
    private sharedService: SharedService, private snackbarService: SnackbarService) {
    this.getTypes();
    this.listPartners();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  getTypes() {
    this.subs.sink = this.sharedService.getTypes().subscribe(
      types => {
        types.titel.forEach(r => {
          this.titels.push(r);
        });
        types.anrede.forEach(r => {
          this.anreden.push(r);
        });
      },
      err => {
        this.sharedService.progress.next(false);
      }
    );
  }
  // List all partners

  listPartners() {
    this.sharedService.progress.next(true);
    this.subs.sink = this.partnerService.listPartnersView()
      .subscribe(
        (partners: WerkstattPartner[]) => {
          this.updateDatasource(partners);
          this.partners = partners;
          this.totalRecords = partners.length;
          this.filteredRecords = this.totalRecords;
          this.sharedService.progress.next(false);
        },
        err => {
          this.sharedService.progress.next(false);
        }
      );
  }

  getNameOrFirma(partner) {
    return this.helpers.getNameOrFirma(partner, this.anreden, this.titels);
  }
  // View Partner
  viewPartner(id) {
    this.router.navigate(['/werkstattPartner/wpartner', id]);
  }

  // Create Partner
  createPartner() {
    this.router.navigate(['/werkstattPartner/create']);
  }

  //#region Mat table datasource
  updateDatasource(partners) {
    // this.displayedColumns = ['name', 'strasse', 'email', 'telefonNummer', 'createdBy'];
    this.dataSource = new MatTableDataSource(partners);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
    this.filteredRecords = this.dataSource.filteredData.length;
  }
  //#endregion

}

