import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  Router
} from '@angular/router';
import { Insurer } from '../../../models/insurer';
import { InsurerService } from 'src/app/components/insurers/insurer.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SharedService } from 'src/app/services/shared.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-listinsurers',
  templateUrl: './listinsurers.component.html',
  styleUrls: ['./listinsurers.component.css', '../../../../tableStyles.scss']
})
export class ListinsurersComponent implements OnInit, OnDestroy {


  //#region Initializations
  insurers: Insurer[] = [];
  displayedColumns = ['insurerName', 'plz', 'ort', 'email', 'telefonNummer'];
  dataSource = new MatTableDataSource(this.insurers);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  totalRecords = 0;
  filteredRecords = 0;
  private subs = new SubSink();
  //#endregion

  constructor(public router: Router, private insurerService: InsurerService,
    private sharedService: SharedService,
    private snackbarService: SnackbarService) {
    this.listInsurers();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  // List all insurers

  listInsurers() {
    this.sharedService.progress.next(true);
    this.subs.sink = this.insurerService.listInsurersView()
      .subscribe(
        (insurers: Insurer[]) => {
          this.updateDatasource(insurers);
          this.insurers = insurers;
          this.totalRecords = insurers.length;
          this.filteredRecords = this.totalRecords;
          this.sharedService.progress.next(false);
        },
        err => {
          this.sharedService.progress.next(false);
        }
      );
  }

  // View Partner
  viewInsurer(id) {
    this.router.navigate(['/insurer', id]);
  }

  //#region Mat table datasource
  updateDatasource(insurers) {
    // this.displayedColumns = ['insurerName', 'strasse', 'email', 'telefonNummer', 'createdBy'];
    this.dataSource = new MatTableDataSource(insurers);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
    this.filteredRecords = this.dataSource.filteredData.length;
  }
  //#endregion

}
