import { ListWerkstattPartnersComponent } from './components/werkstattpartners/list-werkstatt-partners/list-werkstatt-partners.component';
import { ListkfzpartnersComponent } from './components/kfzpartners/listkfzpartners/listkfzpartners.component';
import { CreatekfzpartnerComponent } from './components/kfzpartners/createkfzpartner/createkfzpartner.component';
// tslint:disable-next-line: max-line-length
import { CreateWerkstattPartnerComponent } from './components/werkstattpartners/create-werkstatt-partner/create-werkstatt-partner.component';
import { UbersichtComponent } from './components/ubersicht/ubersicht.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './user-auth/login/login.component';
import { RegisterComponent } from './user-auth/register/register.component';
import { ForgotpasswordComponent } from './user-auth/forgotpassword/forgotpassword.component';
import { ResetpwdlinkComponent } from './user-auth/resetpwdlink/resetpwdlink.component';
import { VerifyuserComponent } from './user-auth/verifyuser/verifyuser.component';
import { ResetpasswordComponent } from './user-auth/resetpassword/resetpassword.component';
import { AuthGuard } from './guards/auth.guard';
import { CreatemandantComponent } from './components/mandanten/createmandant/createmandant.component';
import { ListmandantenComponent } from './components/mandanten/listmandanten/listmandanten.component';
import { ListmandateComponent } from './components/mandate/listmandate/listmandate.component';
import { CreatemandatComponent } from './components/mandate/createmandat/createmandat.component';
import { CreateinsurerComponent } from './components/insurers/createinsurer/createinsurer.component';
import { ListinsurersComponent } from './components/insurers/listinsurers/listinsurers.component';
import { ApplayoutComponent } from './shared/applayout/applayout.component';
import { ConfirmGuard } from './guards/confirm.guard';
import { componentFactoryName } from '@angular/compiler';


const routes: Routes = [
  {
    path: '',
    component: ApplayoutComponent,
    children: [
      { path: '', loadChildren: () => import('./user-auth/user-auth.module').then(m => m.UserAuthModule), canActivate: [AuthGuard], data: { module: 'USER_AUTH' } },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard], data: { module: 'DASHBOARD' } },
      // {
      //   path: 'login',
      //   component: LoginComponent
      // },
      // {
      //   path: 'register',
      //   component: RegisterComponent
      // },
      // {
      //   path: 'forgotpassword',
      //   component: ForgotpasswordComponent
      // },
      // {
      //   path: 'resetpwdlink/:username/:token',
      //   component: ResetpwdlinkComponent
      // },
      // {
      //   path: 'verifyuser/:username/:token',
      //   component: VerifyuserComponent
      // },
      // {
      //   path: 'resetpassword',
      //   component: ResetpasswordComponent,
      //   canActivate: [AuthGuard]
      // },
      // {
      //   path: 'createmandant',
      //   component: CreatemandantComponent,
      //   canActivate: [AuthGuard],
      //   canDeactivate: [AuthGuard]
      // },
      // {
      //   path: 'mandanten',
      //   // component: MandantenComponent,
      //   canActivate: [AuthGuard],
      //   children: [
      //     { path: 'list', component: ListmandantenComponent },
      //     { path: 'mandant/:mandantID', component: CreatemandantComponent, canDeactivate: [AuthGuard] },
      //     { path: 'details/:mandantID', component: CreatemandantComponent, canDeactivate: [AuthGuard] },
      //     { path: 'edit/:mandantID', component: CreatemandantComponent, canDeactivate: [AuthGuard] }
      //   ]
      // },
      // {
      //   path: 'mandate',
      //   // component: MandateComponent,
      //   canActivate: [AuthGuard],
      //   children: [
      //     { path: 'list', component: ListmandateComponent },
      //     { path: 'create/:mandantID', component: CreatemandatComponent, canDeactivate: [AuthGuard] },
      //     { path: 'mandat/:mandantID/:mandatID', component: CreatemandatComponent, canDeactivate: [AuthGuard] },
      //     { path: 'view/:mandantID/:mandatID', component: CreatemandatComponent},
      //     { path: 'edit/:mandantID/:mandatID', component: CreatemandatComponent, canDeactivate: [AuthGuard] }
      //   ]
      // },
      // {
      //   path: 'werkstattPartner',
      //   canActivate: [AuthGuard],
      //   children: [
      //     { path: 'create', component: CreateWerkstattPartnerComponent, canDeactivate: [AuthGuard] },
      //     { path: 'list', component: ListWerkstattPartnersComponent },
      //     { path: 'wpartner/:partnerID', component: CreateWerkstattPartnerComponent, canDeactivate: [AuthGuard] },
      //     { path: 'details/:partnerID', component: CreateWerkstattPartnerComponent, canDeactivate: [AuthGuard] },
      //     { path: 'edit/:partnerID', component: CreateWerkstattPartnerComponent, canDeactivate: [AuthGuard] }
      //   ]
      // },
      // {
      //   path: 'kfzPartner',
      //   canActivate: [AuthGuard],
      //   children: [
      //     { path: 'create', component: CreatekfzpartnerComponent, canDeactivate: [AuthGuard] },
      //     { path: 'list', component: ListkfzpartnersComponent },
      //     { path: 'kfzpartner/:partnerID', component: CreatekfzpartnerComponent, canDeactivate: [AuthGuard] },
      //     { path: 'details/:partnerID', component: CreatekfzpartnerComponent, canDeactivate: [AuthGuard] },
      //     { path: 'edit/:partnerID', component: CreatekfzpartnerComponent, canDeactivate: [AuthGuard] }
      //   ]
      // },
      // {
      //   path: 'insurer',
      //   // component: InsurersComponent,
      //   canActivate: [AuthGuard],
      //   children: [
      //     { path: 'create', component: CreateinsurerComponent, canDeactivate: [AuthGuard] },
      //     { path: 'list', component: ListinsurersComponent },
      //     { path: ':insurerID', component: CreateinsurerComponent, canDeactivate: [AuthGuard] },
      //     { path: 'details/:insurerID', component: CreateinsurerComponent, canDeactivate: [AuthGuard] },
      //     { path: 'edit/:insurerID', component: CreateinsurerComponent, canDeactivate: [AuthGuard] }
      //   ]
      // },
    ],
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false }), RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
