import { SharedService } from './../../services/shared.service';
import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class Helpers {

    /* getFullName(object) {
        return object.anrede + ' ' + this.getTitelOrNull(object.titel) + object.nachname + ', ' + object.vorname;
    } */
    /* getNameOrFirma(object) {
        if (object.type === 'Unternehmen') {
            return object.firmenName;
        } else {
            return object.anrede + ' ' + this.getTitelOrNull(object.titel) + object.nachname + ', ' + object.vorname;
        }
    } */
    getNameOrFirma(object, anreden, titels) {
        if (object.type === 2) {
            return object.firmenName;
        } else {
            return this.getTitelOrAnrede(object.anrede, anreden) + ' ' +
                this.getTitelOrAnrede(object.titel, titels) + object.nachname + ', ' + object.vorname;
        }
    }
    getUGName(object, anreden, titels) {
        if (object.ug_Type === 2) {
            return object.ug_firmenName;
        } else {
            return this.getTitelOrAnrede(object.ug_anrede, anreden) + ' ' +
                this.getTitelOrAnrede(object.ug_titel, titels) + object.ug_nachname + ', ' + object.ug_vorname;
        }
    }
    getTitelOrAnrede(id, objects) {
        if (id !== 0) {
            for (const iterator of objects) {
                if (iterator.id === id) {
                    return iterator.value + ' ';
                }
            }
        } else {
            return '';
        }
    }
    getBegruendungOrStatus(id, list) {
        if (id !== 0) {
            for (const iterator of list) {
                if (iterator.id === id) {
                    return iterator.value;
                }
            }
        } else {
            return 'NA';
        }
    }
    getAnyValue(id, list) {
        if (id !== 0) {
            for (const iterator of list) {
                if (iterator.id === id) {
                    return iterator.value;
                }
            }
        } else {
            return 'NA';
        }
    }
    checkduplicate(control: AbstractControl, input): { [key: string]: any } | null {
        const existingArray = input;
        const value = control.value;
        for (const item of existingArray) {
            if (item.nachname === value || item.email === value) {
                return { customRequired: true };
            }
        }
        return null;
    }
}
