import { Sachbearbeiter } from './../../../models/insurer';
import { MandateService } from 'src/app/components/mandate/mandate.service';
import { InsurerService } from 'src/app/components/insurers/insurer.service';
import { Component, OnInit, HostListener, ViewChild, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  AbstractControl
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Location, LocationStrategy } from '@angular/common';
import { SharedService } from 'src/app/services/shared.service';
import { CanComponentDeactivate } from '../../../guards/auth.guard';
import { Insurer } from 'src/app/models/insurer';
import { MandatsTableComponent } from '../../shared/mandats-table/mandats-table.component';
import { Mandat } from 'src/app/models/mandat';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Helpers } from '../../shared/helper-functions';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SubSink } from 'subsink';
import { browserRefresh } from 'src/app/app.component';

@Component({
  selector: 'app-createinsurer',
  templateUrl: './createinsurer.component.html',
  styleUrls: ['./createinsurer.component.css']
})
export class CreateinsurerComponent implements OnInit, OnDestroy {

  //#region Initializations
  sachbearbeiter: Sachbearbeiter[] = [];
  insurerForm: FormGroup;
  insurer: Insurer;
  insurerID: string;
  @ViewChild(MandatsTableComponent) child: MandatsTableComponent;
  public errMsg = {
    message: 'No alerts'
  };
  saved = false;
  created = false;
  pageMode = 'create';
  sBAddFlag = false;
  sBEditFlag = false;
  anreden = [
    { id: 0, value: '-- Anrede auswählen --' },
  ];
  titels = [
    { id: 0, value: '-- Titel auswählen --' }
  ];
  displaySBColumns = ['name', 'email', 'telefonNummer', 'options'];
  sBDataSource: any;
  public sBFormData: any;
  sBIndex: any;
  helpers = new Helpers();
  @ViewChild(MatSort) sort: MatSort;
  private subs = new SubSink();
  sBModal: MatDialogRef<unknown, any>;
  //#endregion

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private insurerService: InsurerService,
    private formBuilder: FormBuilder,
    private location: Location,
    private sharedService: SharedService,
    private location1: LocationStrategy,
    private snackbarService: SnackbarService,
    private mandateService: MandateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<any>,
    public dialog: MatDialog
  ) {
    this.getTypes();
    this.subs.sink = this.route.paramMap.subscribe(params => {
      this.insurerID = params.get('insurerID');
      if (data.insurerID) {
        this.insurerID = data.insurerID;
      }
      if (this.insurerID) {
        this.pageMode = 'view';
        this.subs.sink = this.insurerService.viewOneInsurer(this.insurerID)
          .subscribe(
            (insurer: Insurer) => {
              this.insurer = insurer; this.sachbearbeiter = insurer.sachbearbeiters; console.log(insurer); this.proceed();
            }
          );
      }
    });
  }

  @HostListener('window:beforeunload')
  canDeactivate() {
    if (this.saved || this.created || !this.insurerForm.dirty) {
      if (!this.created && this.pageMode === 'edit') {
        this.clearLock();
      }
      return true;
    } else {
      if (confirm('Möchten Sie die getätigten Änderungen verwerfen und ohne Speichern verlassen?')) {
        this.clearLock();
        return true;
      } else {
        return false;
      }
    }
  }


  clearLock() {
    this.subs.sink = this.sharedService.clearLock(this.insurerID)
      .subscribe(data => {
      });
  }

  ngOnInit() {
    this.insurerForm = this.formBuilder.group({
      id: new FormControl(null, Validators.nullValidator),
      insurerName: new FormControl(null, [Validators.required]),
      strasse: new FormControl(null, [Validators.required]),
      hausNummer: new FormControl(null, [Validators.required]),
      plz: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')]),
      ort: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.email, Validators.required]),
      telefonNummer: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(30)]),
      sachbearbeiter: new FormArray([]),
    });

    if (this.data.insurerID) {
      this.subs.sink = this.matDialogRef.afterClosed().subscribe(action => {
        this.clearLock();
      });
    }

    if (browserRefresh) {
      this.clearLock();
    }
  }

  ngOnDestroy() {
    this.clearLock();
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  getTypes() {
    this.subs.sink = this.sharedService.getTypes().subscribe(
      types => {
        types.titel.forEach(r => {
          this.titels.push(r);
        });
        types.anrede.forEach(r => {
          this.anreden.push(r);
        });
      },
      err => {
        this.router.navigate(['/insurer/list']);
      }
    );
  }

  getNameOrFirma(object) {
    return this.helpers.getNameOrFirma(object, this.anreden, this.titels);
  }

  // Proceed aftet ngOnInit finishes subscribe

  proceed() {
    if (this.insurerID && this.router.url.includes('insurer')) {
      this.subs.sink = this.mandateService.viewMandateofInsurer(this.insurer.insurerName)
        .subscribe(
          (mandate: Mandat[]) => { this.child.loadTable(mandate); this.viewInsurer(); }
        );
    } else if (this.router.url.includes('create')) {
      this.router.navigate(['/insurer/create']);
    } else if (this.insurerID && this.router.url.includes('mandat')) {
      this.viewInsurer();
    } else {
      this.location.back();
    }
  }

  viewInsurer() {
    this.pageMode = 'view';
    this.insurerForm.disable();
    this.insurerForm.patchValue(this.insurer);
    this.updateSBDatasource();
  }
  //#region Validations

  get validations() { return this.insurerForm.controls; }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  //#endregion

  //#region CreateInsurer functions

  createInsurer() {
    if (!this.insurerForm.valid) {
      this.validateAllFormFields(this.insurerForm);
      console.log('invalid');
      return;
    } else {
      this.sharedService.progress.next(true);
      this.subs.sink = this.insurerService.createInsurer(JSON.stringify(this.insurerForm.value))
        .subscribe(
          (data: Insurer) => {
            this.created = true;
            console.log(data); this.router.navigate(['/insurer/list']);
            this.snackbarService.open(`Insurer ${data.id} ${data.insurerName} wurde angelegt`, 'success');
          },
          error => {
            {
              console.error(error); this.createError(error);
              this.snackbarService.open(this.errMsg.message, 'failure');
            }
          }
        );
      this.sharedService.progress.next(false);
    }
  }

  createError(error: HttpErrorResponse) {
    this.errMsg = error.error;
  }
  //#endregion

  //#region Populate Insurer form
  editInsurer() {
    if (this.pageMode === 'view') {
      this.subs.sink = this.sharedService.checkLock('insurer', this.insurerID)
        .subscribe(data => {
          this.pageMode = 'edit';
          this.insurerForm.patchValue(this.insurer);
          this.insurerForm.enable();
        }, error => {
          this.createError(error);
          this.snackbarService.open(this.errMsg.message, 'failure');
        });
    } else {
      this.saveInsurer();
    }
  }
  //#endregion

  //#region Save Insurer functions
  saveInsurer() {
    if (!this.insurerForm.valid) {
      this.validateAllFormFields(this.insurerForm);
      return;
    } else {
      this.sharedService.progress.next(true);
      console.log(this.insurerForm.value);
      this.subs.sink = this.insurerService
        .updateInsurer(
          JSON.stringify(this.insurerForm.value),
          this.insurerID
        )
        .subscribe(
          (data: Insurer) => {
            this.saved = true; this.insurer = data;
            this.sachbearbeiter = data.sachbearbeiters;
            this.viewInsurer(); this.clearLock();
            this.snackbarService.open(`Insurer ${this.insurer.id} ${this.insurer.insurerName} wurde gespeichert`, 'success');
            this.dialog.closeAll();
          },
          error => {
            {
              console.error(error);
              this.createError(error);
              this.snackbarService.open(this.errMsg.message, 'failure');
            }
          }
        );
      this.sharedService.progress.next(false);
    }
  }
  //#endregion

  deleteInsurer() {
    if (confirm('Möchten Sie mit dem löschen fortfahren? ')) {
      this.sharedService.progress.next(true);
      this.subs.sink = this.insurerService.deleteInsurer(this.insurerID)
        .subscribe(data => {
          this.clearLock();
          this.router.navigate(['/insurer/list']);
        });
      this.sharedService.progress.next(false);
    }
  }

  createSachbearbeiter(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(null, Validators.nullValidator),
      anrede: new FormControl(this.anreden[0].id, [Validators.required, this.customRequired.bind(this)]),
      titel: new FormControl(this.titels[0].id, [Validators.nullValidator]),
      vorname: new FormControl(null, [Validators.required]),
      nachname: new FormControl(null, [Validators.required, this.checkduplicateSB.bind(this)]),
      email: new FormControl(null, [Validators.email, Validators.required, this.checkduplicateSB.bind(this)]),
      telefonNummer: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(30)]),
      deleted: new FormControl(null, Validators.nullValidator),
      insurerId: new FormControl(null, Validators.nullValidator),
    });
  }

  setexistingSachbearbeiter(sachbearbeiter: Sachbearbeiter[]): FormArray {
    const formArray = new FormArray([]);
    sachbearbeiter.forEach(s => {
      formArray.push(
        this.formBuilder.group({
          id: s.id,
          anrede: s.anrede,
          titel: s.titel,
          vorname: s.vorname,
          nachname: s.nachname,
          email: s.email,
          telefonNummer: s.telefonNummer,
          deleted: s.deleted,
          insurerId: s.insurerId
        })
      );
    });
    return formArray;
  }

  //#region Validations
  customRequired(control: AbstractControl): { [key: string]: any } | null {
    const anrede: number = control.value;
    if (anrede === 0) {
      return { customRequired: true };
    } else {
      return null;
    }

  }
  //#endregion

  newSBModal(sBTemplate) {
    this.sBEditFlag = false;
    this.sBAddFlag = true;
    this.sBFormData = this.createSachbearbeiter();
    this.sBModal = this.dialog.open(sBTemplate, {
      width: 'auto', disableClose: true
    });
  }

  editSBModal(sBTemplate, row, index) {
    this.sBAddFlag = false;
    this.sBEditFlag = true;
    this.sBFormData = row;
    this.sBIndex = index;
    this.sBModal = this.dialog.open(sBTemplate, {
      width: 'auto', disableClose: true
    });
  }

  closeSBModal(): void {
    this.updateSBDatasource();
    this.sBModal.close();
  }

  closeInsurerModal(): void {
    this.dialog.closeAll();
  }

  checkduplicateSB(control: AbstractControl): { [key: string]: any } | null {
    const sBArray = this.insurerForm.get('sachbearbeiter').value;
    return this.helpers.checkduplicate(control, sBArray);
  }
  addSachbearbeiter() {
    if (!this.sBFormData.valid) {
      this.validateAllFormFields(this.sBFormData);
    } else if (this.sBFormData.dirty && this.sBFormData.valid) {
      this.sachbearbeiter.push(this.sBFormData.value);
      this.updateSBDatasource(); this.closeSBModal();
    } else {
      alert('No changes made');
    }
  }

  saveSachbearbeiter(index) {
    // const temp = [...this.ansprechPartner1];
    // const duplicate = this.helpers.duplicateFieldCheckAP(temp.splice(index, 1), this.sBFormData.value);

    // else if (duplicate.exists) {
    //   this.snackbarService.open(duplicate.message, 'failure');
    // }
    if (!this.sBFormData.valid) {
      this.validateAllFormFields(this.sBFormData);
    } else if (this.sBFormData.dirty && this.sBFormData.valid) {
      this.sachbearbeiter[index] = this.sBFormData.value;
      this.updateSBDatasource();
      this.closeSBModal();
    } else {
      this.snackbarService.open('No changes made', 'warning');
    }
  }

  // Delete Partner

  deleteSachbearbeiter(index) {
    if (confirm('Möchten Sie mit dem löschen fortfahren?')) {
      this.sachbearbeiter.splice(index, 1);
      this.updateSBDatasource();
    }
  }

  updateSBDatasource() {
    this.insurerForm.setControl(
      'sachbearbeiter',
      this.setexistingSachbearbeiter(this.sachbearbeiter)
    );
    this.sBDataSource = new MatTableDataSource((this.insurerForm.get('sachbearbeiter') as FormArray).controls);
    this.sBDataSource.sort = this.sort;
  }
}

