import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.css']
})
export class PdfPreviewComponent implements OnInit {
  fileSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<any>
  ) {
    if (data) {
      // if (typeof (FileReader) !== 'undefined') {
      //   let reader = new FileReader();
      //   reader = data.file;
      //   reader.onload = (e: any) => {
      //     console.log(e)
      //     this.pdfSrc = e.target.result;
      //   };
      // }
      // console.log(data);
      // this.pdfSrc = data.file
      if (typeof (FileReader) !== 'undefined') {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.fileSrc = e.target.result;
        };
        if (data.file.type === "application/pdf") {
          reader.readAsArrayBuffer(data.file);
        } else {
          reader.readAsDataURL(data.file);
        }
      }
    }
  }

  ngOnInit(): void {
  }
  closePreview() {
    this.matDialogRef.close();
  }
}
