import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../userlogin/user.service';
import { configData } from '../../config.data';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Mandant } from '../../models/mandant';
import { HandleError, HttpErrorHandler } from '../../services/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class MandantenService {

  private handleError: HandleError;
  constructor(private http: HttpClient, private router: Router,
    private user: UserService, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('MandantenService');
  }

  expressLink = configData.SERVER + 'mandant/';
  // Create a new Mandant
  createMandant(body: any) {
    return this.http.post(this.expressLink + 'mandant' + '/?autor=' + this.user.loggedinUser, body, {
      observe: 'body',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get all mandanten

  viewMandanten(): Observable<Mandant[]> {
    return this.http.get<Mandant[]>(this.expressLink + 'mandanten')
      .pipe(
        catchError(this.handleError('getMandanten', []))
      );
  }
  // Get all mandanten by View
  viewMandantenList(): Observable<Mandant[]> {
    return this.http.get<Mandant[]>(this.expressLink + 'mandantenView')
      .pipe(
        catchError(this.handleError('getMandanten', []))
      );
  }

  // Get one Mandant details
  viewOneMandant(mandantID) {
    return this.http.get(this.expressLink + 'mandant/' + mandantID, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Update Mandant by Id
  updateMandant(body: any, mandantId) {
    return this.http.put(this.expressLink + 'updatemandant/' + mandantId + '/?autor=' + this.user.loggedinUser, body, {
      observe: 'body',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Delete the Mandant
  deleteMandant(id) {
    return this.http.delete(this.expressLink + 'deletemandant/' + id + '/?autor=' + this.user.loggedinUser, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
}
