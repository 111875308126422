import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { io } from "socket.io-client";
import { configData } from '../config.data';

@Injectable({
    providedIn: 'root'
})

export class SocketService {
    
    constructor() { }

    public socketSubject = new Subject<any>();

    public socket : any;

    connect(){
        this.socket = io(configData.SERVER);
        this.startListening();
    }

    startListening(){
        this.socket.on('new_comment', ({ comment }) => {
            this.socketSubject.next({ type: 'new_comment', comment });
        })

        this.socket.on('edit_comment', ({ comment }) => {
            this.socketSubject.next({ type: 'edit_comment', comment });
        })

        this.socket.on('delete_comment', ({ comment }) => {
            this.socketSubject.next({ type: 'delete_comment', comment });
        })

        this.socket.on('new_step', ({ step }) => {
            this.socketSubject.next({ type: 'new_step', step });
        })
    }

    disconnect(){
        console.log('disconnecting');
        this.socket.disconnect();
    }

}