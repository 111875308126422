import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router } from '@angular/router';
import { SharedService } from '../services/shared.service';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean;
}

@Injectable()
export class CheckDeactivationGuard implements CanDeactivate<any>{
  constructor(private router: Router, private sharedService: SharedService, private location: Location) { }

  canDeactivate(component: CanComponentDeactivate, currentRoute: ActivatedRouteSnapshot): boolean {
    if (!component.canDeactivate()) {
      const currentUrlTree = this.router.createUrlTree([], currentRoute);
      const currentUrl = currentUrlTree.toString();
      this.location.go(currentUrl);
      return false;
    } else {
      return true;
    }
  }
}
