import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppalertsComponent } from '../components/appalerts/appalerts.component';

@Injectable()
export class SnackbarService {
  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone
  ) {

  }

  public open(message, action) {
    this.zone.run(() => {
      switch (action) {
        case 'success':
          this.snackBar.openFromComponent(AppalertsComponent, {
            duration: 4000, data: { message, icon: 'check' }, panelClass: ['alert-success'],
            verticalPosition: 'top', horizontalPosition: 'end'
          });
          break;
        case 'failure':
          this.snackBar.openFromComponent(AppalertsComponent, {
            data: { message, icon: 'close' }, panelClass: ['alert-danger'], verticalPosition: 'top', horizontalPosition: 'end'
          });
          break;
        case 'warning':
          this.snackBar.openFromComponent(AppalertsComponent, {
            data: { message, icon: 'close' }, panelClass: ['alert-warning'], verticalPosition: 'top', horizontalPosition: 'end'
          });
          break;
      }
    });
  }
}
