import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';
import { SubSink } from 'subsink';
import { AppService } from 'src/app/services/app.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit, OnDestroy {
  public forgotForm: FormGroup;
  private subs = new SubSink();

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<ForgotpasswordComponent>,
    private sharedService: SharedService, public appService: AppService) {}
  
  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  forgotPassword() {
    if (!this.forgotForm.valid) {
      this.validateAllFormFields(this.forgotForm);
      return;
    } else {
      this.sharedService.progress.next(true);
      this.subs.sink = this.appService.forgotPassword(JSON.stringify(this.forgotForm.value))
        .pipe(finalize(() => this.sharedService.progress.next(false)))
        .subscribe(
          data => {
            this.sharedService.open('Reset link has been sent to your email', 'success');
            this.closeModal();
          },
          error => {
            console.error(error);
            this.sharedService.open(error.error.message, 'failure');
          }
        );
    }
  }

  get getEmail() { return this.forgotForm.get('email'); }

  closeModal(): void {
    this.dialogRef.close();
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
