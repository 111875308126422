import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ResetpwdlinkComponent } from './resetpwdlink/resetpwdlink.component';
import { VerifyuserComponent } from './verifyuser/verifyuser.component';

const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'resetpassword', component: ResetpasswordComponent },
    { path: 'resetpwdlink/:username/:token', component: ResetpwdlinkComponent },
    { path: 'verifyuser/:username/:token', component: VerifyuserComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserAuthRoutingModule { }