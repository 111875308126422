import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../userlogin/user.service';
import { configData } from '../../config.data';

@Injectable({
  providedIn: 'root'
})
export class InsurerService {

  constructor(private http: HttpClient, private router: Router,
    private user: UserService) { }

  expressLink = configData.SERVER + 'insurer/';

  // Create a new Insurer
  createInsurer(body: any) {
    return this.http.post(this.expressLink + 'insurer/new' + '/?autor=' + this.user.loggedinUser, body, {
      observe: 'body',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get all Insurers
  listInsurers() {
    return this.http.get(this.expressLink + 'insurers', {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get all Insurers View
  listInsurersView() {
    return this.http.get(this.expressLink + 'insurersView', {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get one Insurer details
  viewOneInsurer(insurerID) {
    return this.http.get(this.expressLink + 'insurer/' + insurerID, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Update Insurer by Id
  updateInsurer(body: any, insurerID) {
    return this.http.put(this.expressLink + 'updateinsurer/' + insurerID + '/?autor=' + this.user.loggedinUser, body, {
      observe: 'body',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Delete the Insurer
  deleteInsurer(insurerID) {
    return this.http.delete(this.expressLink + 'deleteinsurer/' + insurerID + '/?autor=' + this.user.loggedinUser, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get all Insurer names
  insurerNames() {
    return this.http.get(this.expressLink + 'insurerNames', {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
}
