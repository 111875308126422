import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { configData } from '../config.data';
import { SharedService } from "./shared.service";

@Injectable({ providedIn: 'root' })
export class AppService {
    public baseUrl: string = configData.SERVER;

    constructor(private http: HttpClient, public sharedService: SharedService) {}

    login(body) {
        return this.http.post(`${this.baseUrl}${configData.version}${configData.login}`, body);
    }

    forgotPassword(body) {
        return this.http.post(`${this.baseUrl}${configData.version}${configData.forgotPassword}`, body);
    }

    resetPassword(body) {
        return this.http.post(`${this.baseUrl}${configData.version}${configData.resetPassword}`, body);
    }

    logout() {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.logout}`);
    }

    updateTheme(themeName: string) {
        return this.http.put(`${this.baseUrl}${configData.version}${configData.updateTheme}`, { themeName });
    }

    instituteTypeList() {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.typeOfInstitution}`);
    }

    createInstitution(payload) {
        return this.http.post(`${this.baseUrl}${configData.version}${configData.institute}`, payload);
    }

    institutionDetail(instituteMappingId) {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.institute}/${instituteMappingId}`);
    }

    updateInstitution(payload) {
        return this.http.put(`${this.baseUrl}${configData.version}${configData.institute}`, payload);
    }

    deleteInstitution(instituteMappingId) {
        return this.http.delete(`${this.baseUrl}${configData.version}${configData.institute}/${instituteMappingId}`);
    }

    institutionUser(institutionId) {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.institute}${configData.user}?institutionId=${institutionId}`);
    }

    createInstitutionUser(payload) {
        return this.http.post(`${this.baseUrl}${configData.version}${configData.institute}${configData.user}`, payload);
    }

    salutationTitleList() {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.titleAndSalutation}`);
    }

    editInstitutionUser(payload) {
        return this.http.put(`${this.baseUrl}${configData.version}${configData.institute}${configData.user}`, payload);
    }

    deleteInstitutionUser(userId) {
        return this.http.delete(`${this.baseUrl}${configData.version}${configData.institute}${configData.user}/${userId}`);
    }

    institutionList(institutionId, institutionTypeId, pageNo?, pageSize?, sortObject?) {
        let queryParams = `institutionId=${institutionId}&institutionTypeId=${institutionTypeId}`;        
        const { sortBy = '', sortOrder = '', search = '' } = sortObject || {};
        if(!isNaN(pageNo) && !isNaN(pageSize)){
            queryParams = `${queryParams}&pageNo=${pageNo}&pageSize=${pageSize}`;
        }
        if(sortBy || sortOrder) {
            queryParams = `${queryParams}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        if(search?.trim()?.length) {
            queryParams = `${queryParams}&search=${search}`;
        }
        return this.http.get(`${this.baseUrl}${configData.version}${configData.institute}?${queryParams}`);
    }

    createClient(payload) {
        return this.http.post(`${this.baseUrl}${configData.version}${configData.client}`, payload);
    }

    clientDetail(clientId) {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.client}/${clientId}`);
    }

    editClient(payload) {
        return this.http.put(`${this.baseUrl}${configData.version}${configData.client}`, payload);
    }

    deleteClient(clientId) {
        return this.http.delete(`${this.baseUrl}${configData.version}${configData.client}/${clientId}`);
    }

    clientList(institutionId, pageNo = 0, pageSize = 5, sortObject?){
        const { sortBy = '', sortOrder = '', search = '' } = sortObject;
        let queryParams = `institutionId=${institutionId}&pageNo=${pageNo}&pageSize=${pageSize}`;
        if(sortBy || sortOrder) {
            queryParams = `${queryParams}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        if(search?.trim()?.length) {
            queryParams = `${queryParams}&search=${search}`;
        }
        return this.http.get(`${this.baseUrl}${configData.version}${configData.client}?${queryParams}`);
    }

    caseValues() {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.caseValues}`);
    }

    createCase(payload) {
        var params = new HttpParams();
        params = params.append('isFormDataRequest', 'true'); 
        return this.http.post(`${this.baseUrl}${configData.version}${configData.case}`, payload, { params: params });
    }

    caseList(clientId, sortObject?) {
        const { sortBy = '', sortOrder = '', search = '' } = sortObject || {};
        let params = {};
        params = clientId ? {...params, clientId } : params;
        if(sortBy && sortOrder) {
            params = {...params, sortBy, sortOrder };
        }
        if(search?.trim()?.length) {
            params = {...params, search };
        }
        return this.http.get(`${this.baseUrl}${configData.version}${configData.case}`, { params });
    }

    caseDetail(caseId) {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.case}/${caseId}`);
    }

    updatedCase(caseId, payload) {
        var params = new HttpParams();
        params = params.append('isFormDataRequest', 'true'); 
        return this.http.put(`${this.baseUrl}${configData.version}${configData.case}/${caseId}`, payload, { params: params });
    }

    deleteCase(caseId) {
        return this.http.delete(`${this.baseUrl}${configData.version}${configData.case}/${caseId}`);
    }

    downloadCase(filename) {
        var params = new HttpParams();
        params = params.append('isDownloadRequest', 'true'); 
        return this.http.get(`${this.baseUrl}${configData.version}${configData.case}${configData.download}?filename=${filename}`, { params: params });
    }

    createComment(payload) {
        return this.http.post(`${this.baseUrl}${configData.version}${configData.comments}`, payload);
    }

    updateComment(commentId, body) {
        return this.http.patch(`${this.baseUrl}${configData.version}${configData.comments}/${commentId}`, { body });
    }

    getComments(caseId) {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.comments}/${caseId}`);
    }

    deleteComment(commentId) {
        return this.http.delete(`${this.baseUrl}${configData.version}${configData.comments}/${commentId}`);
    }

    instituteTypeUser(instituteType) {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.instituteType}${configData.user}?instituteType=${instituteType}`);
    }

    documentList(caseId) {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.document}?caseId=${caseId}`);
    }

    createFolder(payload) {
        return this.http.post(`${this.baseUrl}${configData.version}${configData.document}${configData.folder}`, payload);
    }

    createFile(payload) {
        var params = new HttpParams();
        params = params.append('isFormDataRequest', 'true'); 
        return this.http.post(`${this.baseUrl}${configData.version}${configData.document}${configData.file}`, payload, { params: params });
    }

    updateDoc(payload, documentId) {
        return this.http.put(`${this.baseUrl}${configData.version}${configData.document}/${documentId}`, payload);
    }

    deleteDoc(documentId) {
        return this.http.delete(`${this.baseUrl}${configData.version}${configData.document}/${documentId}`);
    }

    templateList(pageNo = 0, pageSize = 5, sortObject?) {
        const { sortBy = '', sortOrder = '', search = '' } = sortObject;
        let queryParams = `pageNo=${pageNo}&pageSize=${pageSize}`;
        if(sortBy || sortOrder) {
            queryParams = `${queryParams}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        if(search?.trim()?.length) {
            queryParams = `${queryParams}&search=${search}`;
        }
        return this.http.get(`${this.baseUrl}${configData.version}${configData.mailTemplate}?${queryParams}`);
    }

    createTemplate(payload) {
        return this.http.post(`${this.baseUrl}${configData.version}${configData.mailTemplate}`, payload);
    }

    templateDetail(templateId) {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.mailTemplate}/${templateId}`);
    }

    editTemplate(templateId, payload) {
        return this.http.put(`${this.baseUrl}${configData.version}${configData.mailTemplate}/${templateId}`, payload);
    }

    deleteTemplate(templateId) {
        return this.http.delete(`${this.baseUrl}${configData.version}${configData.mailTemplate}/${templateId}`);
    }

    markFavorite(templateId, payload) {
        return this.http.put(`${this.baseUrl}${configData.version}${configData.mailTemplate}${configData.favorite}/${templateId}`, payload);
    }

    listCaseVariable() {
        return  this.http.get(`${this.baseUrl}${configData.version}${configData.caseVariable}`);
    }

    createTextBlock(payload) {
        return this.http.post(`${this.baseUrl}${configData.version}${configData.textBlockTemplate}`, payload);
    }

    textBlockDetail(textBlockId) {
        return this.http.get(`${this.baseUrl}${configData.version}${configData.textBlockTemplate}/${textBlockId}`);
    }

    editTextBlock(textBlockId, payload) {
        return this.http.put(`${this.baseUrl}${configData.version}${configData.textBlockTemplate}/${textBlockId}`, payload);
    }

    deleteTextBlock(textBlockId) {
        return this.http.delete(`${this.baseUrl}${configData.version}${configData.textBlockTemplate}/${textBlockId}`);
    }

    textBlockList(pageNo = 0, pageSize = 5, sortObject?) {
        // const { sortBy = '', sortOrder = '', search = '' } = sortObject;
        let queryParams = `pageNo=${pageNo}&pageSize=${pageSize}`;
        // if(sortBy || sortOrder) {
        //     queryParams = `${queryParams}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        // }
        // if(search?.trim()?.length) {
        //     queryParams = `${queryParams}&search=${search}`;
        // }
        return this.http.get(`${this.baseUrl}${configData.version}${configData.textBlockTemplate}?${queryParams}`);
    }

    sendMail(caseId, payload) {
        return this.http.post(`${this.baseUrl}${configData.version}${configData.case}${configData.sendMail}/${caseId}`, payload);
    }
}