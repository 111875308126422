import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { Alert, AlertType } from './alert.model';
import { AlertService } from './alert.service';

// tslint:disable-next-line: component-selector
@Component({ selector: 'alert', templateUrl: 'alert.component.html' })
export class AlertComponent implements OnInit, OnDestroy {
    @Input() id: string;

    alerts: Alert[] = [];
    subscription: Subscription;
    successAlert = false;
    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.subscription = this.alertService.onAlert(this.id)
            .subscribe(alert => {
                let exists;
                if (!alert.message) {
                    // clear alerts when an empty alert is received
                    this.alerts = [];
                    return;
                } else {
                    // tslint:disable-next-line: prefer-for-of
                    for (let i = 0; i < this.alerts.length; i++) {
                        if (this.alerts[i].message.includes(alert.message)) {
                            exists = true;
                        }
                    }
                    if (alert.type === 0) {
                        this.alerts = [];
                        this.alerts.push(alert);
                        setTimeout(() => {
                            this.removeAlert(alert);
                        }, 5000);
                    } else if (!exists) {
                        this.alerts.push(alert);
                    }
                }
            });
    }

    ngOnDestroy() {
        // unsubscribe to avoid memory leaks
        this.subscription.unsubscribe();
    }

    removeAlert(alert: Alert) {
        // remove specified alert from array
        this.alerts = this.alerts.filter(x => x !== alert);
    }

    cssClass(alert: Alert) {
        if (!alert) {
            return;
        }
        // return css class based on alert type
        switch (alert.type) {
            case AlertType.Success:
                this.successAlert = true;
                return 'alert alert-success';
            case AlertType.Error:
                return 'alert alert-danger';
            case AlertType.Info:
                return 'alert alert-info';
            case AlertType.Warning:
                return 'alert alert-warning';
        }
    }
}
