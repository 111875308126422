import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { UserAuthRoutingModule } from './user-auth-routing.module';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { RegisterComponent } from './register/register.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ResetpwdlinkComponent } from './resetpwdlink/resetpwdlink.component';
import { VerifyuserComponent } from './verifyuser/verifyuser.component';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotpasswordComponent,
    ResetpasswordComponent,
    ResetpwdlinkComponent,
    VerifyuserComponent
  ],
  imports: [
    CommonModule,
    UserAuthRoutingModule,
    SharedModule,
    MatPasswordStrengthModule,
  ],
  entryComponents: [
    ForgotpasswordComponent,
    RegisterComponent
  ]
})
export class UserAuthModule { }
