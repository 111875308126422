import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { SharedService } from '../services/shared.service';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean;
}

@Injectable()
export class AuthGuard implements CanActivate{
  constructor(private router: Router, private sharedService: SharedService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if(route.data.module === 'USER_AUTH'){
      if (!this.sharedService.loggedIn()) {
        return true;
      }
      this.router.navigate(['/dashboard']);
      return false;
    }

    if(route.data.module === 'DASHBOARD'){
      if (this.sharedService.loggedIn()) {
        return true;
      }
      this.router.navigate(['/']);
      return false;
    }
    
  }

  // canDeactivate(component: CanComponentDeactivate, currentRoute: ActivatedRouteSnapshot): boolean {
  //   if (!component.canDeactivate()) {
  //     const currentUrlTree = this.router.createUrlTree([], currentRoute);
  //     const currentUrl = currentUrlTree.toString();
  //     this.location.go(currentUrl);
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
}
